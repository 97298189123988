@import '_Variables.scss';

.box-content-tracking {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 15px;
  >.box-content-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    padding: 8px 15px;
    i {
      color: #DADADA;
      font-size: 14px;
    }
    .ct-left {
      flex: 1;
      >p {
        margin-top: -6px;
        font-size: 18px;
        color: $secondary;
      }
      >small {
        font-size: 12px;
      }
    }
  }
  >.box-content-detail {
    border-bottom: 1px solid #F0F0F0;
    padding: 8px 15px;
    >.section-detail {
      display: flex;
    }
    .ct-left {
      flex: 1;
    }
    .ct-right {
      text-align: right;
    }
    p {
      font-size: 14px;
      margin-top: -6px;
    }
    small {
      font-size: 10px;
    }
  }
}

.box-tracking-statuses {
  display: flex;
  margin-top: 12px;
  position: relative;
  > div {
    flex: 1;
    text-align: center;
    &.checked {
      >p {
        color: $secondary !important;
      }
      .status-icon {
        background: #fff;
        border-color: $secondary !important;
        color: $secondary !important;
        &::before {
          border-top-color: $secondary !important;
        }
      }
    }
    &.status-picked {
      &.active {
        >p {
          color: $pick;
        }
        .status-icon {
          background: #fff;
          border-color: $pick;
          color: $pick;
          &::before {
            border-top-color: $pick;
          }
        }
      }
    }
    &.status-packed {
      &.active {
        >p {
          color: $pack;
        }
        .status-icon {
          background: #fff;
          border-color: $pack;
          color: $pack;
          &::before {
            border-top-color: $pack;
          }
        }
      }
    }
    &.status-in-transit {
      &.active {
        >p {
          color: $intransit;
        }
        .status-icon {
          background: #fff;
          border-color: $intransit;
          color: $intransit;
          &::before {
            border-top-color: $intransit;
          }
        }
      }
    }
    &.status-delivered {
      &.active {
        >p {
          color: $delivered;
        }
        .status-icon {
          background: #fff;
          border-color: $delivered;
          color: #fff;
          &::before {
            font-family: 'icomoon';
            content: '\e90f';
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            border-top: none;
            background: rgba(28, 189, 165, 0.6);
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $delivered;
          }
        }
      }
    }
    &.status-undelivered {
      >p {
        color: $fail;
      }
      .status-icon {
        border-color: $fail;
        color: $fail;
        &::before {
          border-top-color: $fail;
        }
      }
    } 
    .status-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: #f3f3f3;
      border: 2px solid #a7a7a7;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      >i {
        font-size: 24px;
        position: relative;
        z-index: 1;
      }
      &::before {
        content: '';
        position: absolute;
        width: 170%;
        right: -170%;
        border-top: 3px solid #858585;
      }
    }
    &:last-of-type {
      .status-icon::before {
        content: '';
        position: relative;
        width: 0;
        right: 0;
        border-top: none;
      }
    }
    p {
      margin-top: 4px;
      font-weight: 400;
      color: #858585;
      font-size: 12px;
    }
    small {
      display: block;
      font-size: 9px;
      margin-top: -2px;
    }
  }
}

.order-tracking-detail {
  margin-top: 6px;
  .order-tracking-detail-header, .order-tracking-detail-body {
    display: flex;
    .text-left {
      flex: 1;
    }
  }
  .order-tracking-detail-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
    margin-bottom: 2px;
    font-size: 10px;
    color: $secondary;
  }
  .order-tracking-detail-body {
    font-size: 14px;
    color: #444;
    font-weight: 300;
  }
}
