@import '_Variables.scss';

.box-content {
  >small {
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
  }
  .profile-avatar {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    >.avatar {
      width: 46px;
      height: 46px;
      background: #eee;
      border-radius: 50%;
      margin-right: 12px;
    }
    >.info {
      >p {
        font-size: 24px;
        color: $secondary;
        margin-top: -12px;
      }
      >small {
        display: block;
        margin-top: -8px;
        color: #B1BECE;
        font-size: 12px;
      }
    }
  }
  .info-from-control {
    padding-bottom: 8px;
    margin-top: 6px;
    border-bottom: 1px solid #F0F0F0;
    >small {
      font-size: 12px;
    }
    >p {
      margin-top: -2px;
    }
  }
  .from-control {
    margin-bottom: 12px;
    >p {
      margin-top: -2px;
    }
  }
}
.box-logout {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F43F40;
  font-size: 14px;
  margin: 26px auto;
  >i {
    font-size: 18px;
    margin-right: 12px;
  }
}