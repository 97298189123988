@import url(https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai);
@import url(https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai);
@import url(https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai);
.box-content-tracking {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 15px; }
  .box-content-tracking > .box-content-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    padding: 8px 15px; }
    .box-content-tracking > .box-content-header i {
      color: #DADADA;
      font-size: 14px; }
    .box-content-tracking > .box-content-header .ct-left {
      flex: 1 1; }
      .box-content-tracking > .box-content-header .ct-left > p {
        margin-top: -6px;
        font-size: 18px;
        color: #002F6C; }
      .box-content-tracking > .box-content-header .ct-left > small {
        font-size: 12px; }
  .box-content-tracking > .box-content-detail {
    border-bottom: 1px solid #F0F0F0;
    padding: 8px 15px; }
    .box-content-tracking > .box-content-detail > .section-detail {
      display: flex; }
    .box-content-tracking > .box-content-detail .ct-left {
      flex: 1 1; }
    .box-content-tracking > .box-content-detail .ct-right {
      text-align: right; }
    .box-content-tracking > .box-content-detail p {
      font-size: 14px;
      margin-top: -6px; }
    .box-content-tracking > .box-content-detail small {
      font-size: 10px; }

.box-tracking-statuses {
  display: flex;
  margin-top: 12px;
  position: relative; }
  .box-tracking-statuses > div {
    flex: 1 1;
    text-align: center; }
    .box-tracking-statuses > div.checked > p {
      color: #002F6C !important; }
    .box-tracking-statuses > div.checked .status-icon {
      background: #fff;
      border-color: #002F6C !important;
      color: #002F6C !important; }
      .box-tracking-statuses > div.checked .status-icon::before {
        border-top-color: #002F6C !important; }
    .box-tracking-statuses > div.status-picked.active > p {
      color: #CCB122; }
    .box-tracking-statuses > div.status-picked.active .status-icon {
      background: #fff;
      border-color: #CCB122;
      color: #CCB122; }
      .box-tracking-statuses > div.status-picked.active .status-icon::before {
        border-top-color: #CCB122; }
    .box-tracking-statuses > div.status-packed.active > p {
      color: #BD8647; }
    .box-tracking-statuses > div.status-packed.active .status-icon {
      background: #fff;
      border-color: #BD8647;
      color: #BD8647; }
      .box-tracking-statuses > div.status-packed.active .status-icon::before {
        border-top-color: #BD8647; }
    .box-tracking-statuses > div.status-in-transit.active > p {
      color: #0A4FB6; }
    .box-tracking-statuses > div.status-in-transit.active .status-icon {
      background: #fff;
      border-color: #0A4FB6;
      color: #0A4FB6; }
      .box-tracking-statuses > div.status-in-transit.active .status-icon::before {
        border-top-color: #0A4FB6; }
    .box-tracking-statuses > div.status-delivered.active > p {
      color: #1CBDA5; }
    .box-tracking-statuses > div.status-delivered.active .status-icon {
      background: #fff;
      border-color: #1CBDA5;
      color: #fff; }
      .box-tracking-statuses > div.status-delivered.active .status-icon::before {
        font-family: 'icomoon';
        content: '\e90f';
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        border-top: none;
        background: rgba(28, 189, 165, 0.6);
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #1CBDA5; }
    .box-tracking-statuses > div.status-undelivered > p {
      color: #C14D48; }
    .box-tracking-statuses > div.status-undelivered .status-icon {
      border-color: #C14D48;
      color: #C14D48; }
      .box-tracking-statuses > div.status-undelivered .status-icon::before {
        border-top-color: #C14D48; }
    .box-tracking-statuses > div .status-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: #f3f3f3;
      border: 2px solid #a7a7a7;
      width: 42px;
      height: 42px;
      border-radius: 50%; }
      .box-tracking-statuses > div .status-icon > i {
        font-size: 24px;
        position: relative;
        z-index: 1; }
      .box-tracking-statuses > div .status-icon::before {
        content: '';
        position: absolute;
        width: 170%;
        right: -170%;
        border-top: 3px solid #858585; }
    .box-tracking-statuses > div:last-of-type .status-icon::before {
      content: '';
      position: relative;
      width: 0;
      right: 0;
      border-top: none; }
    .box-tracking-statuses > div p {
      margin-top: 4px;
      font-weight: 400;
      color: #858585;
      font-size: 12px; }
    .box-tracking-statuses > div small {
      display: block;
      font-size: 9px;
      margin-top: -2px; }

.order-tracking-detail {
  margin-top: 6px; }
  .order-tracking-detail .order-tracking-detail-header, .order-tracking-detail .order-tracking-detail-body {
    display: flex; }
    .order-tracking-detail .order-tracking-detail-header .text-left, .order-tracking-detail .order-tracking-detail-body .text-left {
      flex: 1 1; }
  .order-tracking-detail .order-tracking-detail-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
    margin-bottom: 2px;
    font-size: 10px;
    color: #002F6C; }
  .order-tracking-detail .order-tracking-detail-body {
    font-size: 14px;
    color: #444;
    font-weight: 300; }

.box-content > small {
  display: block;
  font-size: 12px;
  margin-bottom: 6px; }

.box-content .profile-avatar {
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .box-content .profile-avatar > .avatar {
    width: 46px;
    height: 46px;
    background: #eee;
    border-radius: 50%;
    margin-right: 12px; }
  .box-content .profile-avatar > .info > p {
    font-size: 24px;
    color: #002F6C;
    margin-top: -12px; }
  .box-content .profile-avatar > .info > small {
    display: block;
    margin-top: -8px;
    color: #B1BECE;
    font-size: 12px; }

.box-content .info-from-control {
  padding-bottom: 8px;
  margin-top: 6px;
  border-bottom: 1px solid #F0F0F0; }
  .box-content .info-from-control > small {
    font-size: 12px; }
  .box-content .info-from-control > p {
    margin-top: -2px; }

.box-content .from-control {
  margin-bottom: 12px; }
  .box-content .from-control > p {
    margin-top: -2px; }

.box-logout {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F43F40;
  font-size: 14px;
  margin: 26px auto; }
  .box-logout > i {
    font-size: 18px;
    margin-right: 12px; }

* {
  font-family: 'Kanit', sans-serif;
  outline: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  background: #F6F8FA;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 300;
  color: #212223; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300; }

ul {
  list-style: none; }

input, select, button {
  height: 36px;
  padding: 2px 12px;
  width: 100%;
  border: 1px solid #CED4DA;
  border-radius: 5px;
  font-size: 14px; }

select {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a, button {
  cursor: pointer;
  text-decoration: none;
  color: #002F6C; }

button, .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  background: #002F6C;
  color: #fff;
  height: 42px;
  padding: 2px 12px;
  text-align: center;
  border: none; }

[disabled] {
  background: #e6e6e6;
  color: #aaa; }

img {
  display: block;
  max-width: 100%; }

#root, .App {
  height: 100%;
  min-width: 100%; }

.Loading {
  position: fixed;
  align-items: center;
  background: #F6F8FA;
  width: 100%;
  min-height: 100%;
  z-index: 1010; }
  .Loading > .box-load {
    display: flex;
    justify-items: center;
    width: 100%;
    min-height: 100vh;
    text-align: center; }
    .Loading > .box-load img {
      margin: auto; }

.mb-3 {
  margin-bottom: 32px !important; }

.text-error {
  color: #dc3545 !important; }

.from-ctrl {
  margin-bottom: 12px; }

.BoxTmps {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .BoxTmps .container {
    max-width: 460px;
    width: 100%;
    padding: 0 15px; }
    .BoxTmps .container > .logo {
      margin-bottom: 12px;
      text-align: center; }
      .BoxTmps .container > .logo img {
        max-width: 160px;
        margin: 0 auto; }
    .BoxTmps .container > .content-box {
      background: #FFFFFF;
      box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
      border-radius: 4px;
      width: 100%;
      padding: 20px; }

.Login, .ForgotPassword {
  position: relative; }
  .Login .title, .ForgotPassword .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 22px; }
  .Login .status-login, .ForgotPassword .status-login {
    color: #dc3545;
    margin-bottom: 12px;
    text-align: center; }
  .Login > p, .ForgotPassword > p {
    text-align: center;
    color: #9C9C9C;
    margin-top: -16px;
    margin-bottom: 32px; }
  .Login .forgot-link, .ForgotPassword .forgot-link {
    font-size: 14px;
    text-align: right;
    margin-top: 18px;
    margin-bottom: 26px; }
  .Login .link-goback, .ForgotPassword .link-goback {
    position: absolute;
    bottom: -102px;
    text-align: center;
    width: 100%; }

.container {
  max-width: 460px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto; }

.MainTmps {
  min-height: 100%;
  min-width: 100%;
  padding: 32px 0 64px 0; }
  .MainTmps > .container {
    flex: 1 1; }
    .MainTmps > .container > .title {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #002F6C;
      margin-bottom: 8px; }
      .MainTmps > .container > .title > i {
        margin-right: 10px; }
    .MainTmps > .container .header-sch {
      display: flex;
      background: #FFFFFF;
      box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
      border-radius: 99px;
      overflow: hidden;
      margin-bottom: 30px; }
      .MainTmps > .container .header-sch .header-button {
        width: 52px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .MainTmps > .container .header-sch .input {
        flex: 1 1;
        padding: 2px 16px;
        line-height: 36px; }
      .MainTmps > .container .header-sch input {
        border: none;
        padding: 2px 16px 2px 0px; }
        .MainTmps > .container .header-sch input.pl-2 {
          padding-left: 16px; }
    .MainTmps > .container > .header {
      display: flex;
      align-items: center;
      margin-bottom: 6px; }
      .MainTmps > .container > .header > .text-left {
        flex: 1 1; }
      .MainTmps > .container > .header > .text-right {
        font-size: 12px;
        color: #002F6C; }

.Navbar {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px -4px 10px rgba(1, 47, 107, 0.05); }
  .Navbar .container {
    display: flex;
    align-items: center;
    height: 64px; }
    .Navbar .container > a {
      flex: 1 1;
      text-align: center;
      color: #6D8085; }
      .Navbar .container > a.active {
        color: #002F6C; }
        .Navbar .container > a.active i {
          padding: 6px 12px;
          background: #eee;
          border-radius: 4px; }
      .Navbar .container > a .nav-icon {
        font-size: 22px; }
      .Navbar .container > a > small {
        display: block;
        font-size: 8px; }

.box-item {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 12px;
  cursor: pointer; }
  .box-item.delivered > .header > .text-left > .tracking-status {
    border-color: #1CBDA5; }
    .box-item.delivered > .header > .text-left > .tracking-status > i {
      color: #1CBDA5; }
  .box-item.delivered > .header > .text-center > .tracking-type {
    color: #1CBDA5; }
  .box-item.packed > .header > .text-left > .tracking-status {
    border-color: #BD8647; }
    .box-item.packed > .header > .text-left > .tracking-status > i {
      color: #BD8647; }
  .box-item.packed > .header > .text-center > .tracking-type {
    color: #BD8647; }
  .box-item.picked > .header > .text-left > .tracking-status {
    border-color: #CCB122; }
    .box-item.picked > .header > .text-left > .tracking-status > i {
      color: #CCB122; }
  .box-item.picked > .header > .text-center > .tracking-type {
    color: #CCB122; }
  .box-item.undelivered > .header > .text-left > .tracking-status {
    border-color: #C14D48; }
    .box-item.undelivered > .header > .text-left > .tracking-status > i {
      color: #C14D48; }
  .box-item.undelivered > .header > .text-center > .tracking-type {
    color: #C14D48; }
  .box-item.in-transit > .header > .text-left > .tracking-status {
    border-color: #0A4FB6; }
    .box-item.in-transit > .header > .text-left > .tracking-status > i {
      color: #0A4FB6; }
  .box-item.in-transit > .header > .text-center > .tracking-type {
    color: #0A4FB6; }
  .box-item > .header {
    padding: 15px 15px;
    display: flex;
    border-bottom: 1px solid #F0F0F0; }
    .box-item > .header > .text-left > .tracking-status {
      margin-right: 10px;
      height: 42px;
      width: 42px;
      border: 2px solid #ddd;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px; }
      .box-item > .header > .text-left > .tracking-status > i {
        color: #ddd; }
    .box-item > .header > .text-center {
      flex: 1 1; }
      .box-item > .header > .text-center > .title {
        font-size: 18px;
        color: #002F6C; }
      .box-item > .header > .text-center > p {
        font-size: 14px;
        margin-top: -4px; }
    .box-item > .header > .text-right {
      color: #DADADA; }
  .box-item > .detail {
    padding: 4px 15px 8px 15px;
    display: flex; }
    .box-item > .detail > .text-left, .box-item > .detail > .text-right {
      flex: 1 1;
      text-align: left; }
      .box-item > .detail > .text-left > p, .box-item > .detail > .text-right > p {
        margin-top: -4px;
        font-size: 14px; }
      .box-item > .detail > .text-left > small, .box-item > .detail > .text-right > small {
        font-size: 11px;
        color: #002F6C; }
    .box-item > .detail > .text-right {
      text-align: right; }

.box-content {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 16px; }

.modal-calendar {
  position: absolute;
  z-index: 2000;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  background: rgba(1, 44, 100, 0.7);
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal-calendar .modal-content {
    background: #FFF;
    border-radius: 5px; }
  .modal-calendar .DayPicker-Day {
    height: 40px;
    width: 40px; }
  .modal-calendar .modal-title {
    text-align: center;
    font-size: 18px;
    color: #002F6C;
    margin-top: 14px; }
  .modal-calendar .modal-footer {
    padding: 16px; }

.yas-pagination {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  overflow: hidden; }

ul.pagination {
  display: flex; }
  ul.pagination > li {
    border-right: none;
    background: #fff;
    flex: 1 1;
    border-right: 1px solid #eee; }
    ul.pagination > li a {
      color: #6c7177;
      font-size: 14px;
      padding: 6px 16px;
      float: left;
      width: 100%;
      text-align: center; }
    ul.pagination > li.disabled a {
      color: #CCC; }
    ul.pagination > li.active a {
      color: #002F6C;
      background-color: #c5cdd6; }
    ul.pagination > li:last-child {
      border-right: none; }

.Warning {
  text-align: center;
  padding: 32px 0 0 0; }
  .Warning > i {
    display: block;
    font-size: 62px;
    color: #dc3545;
    margin-bottom: 42px; }
  .Warning > .w-title {
    font-size: 18px;
    color: #002F6C;
    margin-bottom: 14px; }
  .Warning > a {
    margin-top: 72px; }

