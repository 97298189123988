@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai');
@import '_Variables.scss';
@import '_Reset.scss';

#root, .App {
  height: 100%;
  min-width: 100%;
}

.Loading {
  position: fixed;
  align-items: center;
  background: #F6F8FA;
  width: 100%;
  min-height: 100%;
  z-index: 1010;
  >.box-load {
    display: flex;
    justify-items: center;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    img {
      margin: auto;
    }
  }
}

.mb-3 {
  margin-bottom: 32px !important;
}
.text-error {
  color: $danger !important;
}

.from-ctrl {
  margin-bottom: 12px;
}

.BoxTmps {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 460px;
    width: 100%;
    padding: 0 15px;
    >.logo {
      margin-bottom: 12px;
      text-align: center;
      img {
        max-width: 160px;
        margin: 0 auto;
      }
    }
    >.content-box {
      background: #FFFFFF;
      box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
      border-radius: 4px;
      width: 100%;
      padding: 20px;
    }
  }
}

.Login, .ForgotPassword {
  position: relative;
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 22px;
  }
  .status-login {
    color: $danger;
    margin-bottom: 12px;
    text-align: center;
  }
  >p {
    text-align: center;
    color: #9C9C9C;
    margin-top: -16px;
    margin-bottom: 32px;
  }
  .forgot-link {
    font-size: 14px;
    text-align: right;
    margin-top: 18px;
    margin-bottom: 26px;
  }
  .link-goback {
    position: absolute;
    bottom: -102px;
    text-align: center;
    width: 100%;
  }
}

.container {
  max-width: 460px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.MainTmps {
  min-height: 100%;
  min-width: 100%;
  padding: 32px 0 64px 0;
  >.container {
    flex: 1;
    >.title {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: $secondary;
      margin-bottom: 8px;
      >i {
        margin-right: 10px;
      }
    }
    .header-sch {
      display: flex;
      background: #FFFFFF;
      box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
      border-radius: 99px;
      overflow: hidden;
      margin-bottom: 30px;
      .header-button {
        width: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .input {
        flex: 1;
        padding: 2px 16px;
        line-height: 36px;
      }
      input {
        border: none;
        padding: 2px 16px 2px 0px;
        &.pl-2 {
          padding-left: 16px;
        }
      }
    }
    >.header {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      >.text-left {
        flex: 1;
      }
      >.text-right {
        font-size: 12px;
        color: $secondary;
      }
    }
  }
}

.Navbar {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px -4px 10px rgba(1, 47, 107, 0.05);
  .container {
    display: flex;
    align-items: center;
    height: 64px;
    >a {
      flex: 1;
      text-align: center;
      color: #6D8085;
      &.active {
        color: $secondary;
        i {
          padding: 6px 12px;
          background: #eee;
          border-radius: 4px;
        }
      }
      .nav-icon {
        font-size: 22px;
      }
      >small {
        display: block;
        font-size: 8px;
      }
    }
  }
}

.box-item {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  &.delivered >.header {
    >.text-left >.tracking-status {
      border-color: $delivered;
      >i {
        color: $delivered;
      }
    }
    >.text-center >.tracking-type {
      color: $delivered;
    }
  }
  &.packed >.header {
    >.text-left >.tracking-status {
      border-color: $pack;
      >i {
        color: $pack;
      }
    }
    >.text-center >.tracking-type {
      color: $pack;
    }
  }
  &.picked >.header {
    >.text-left >.tracking-status {
      border-color: $pick;
      >i {
        color: $pick;
      }
    }
    >.text-center >.tracking-type {
      color: $pick;
    }
  }
  &.undelivered >.header {
    >.text-left >.tracking-status {
      border-color: $fail;
      >i {
        color: $fail;
      }
    }
    >.text-center >.tracking-type {
      color: $fail;
    }
  }
  &.in-transit >.header {
    >.text-left >.tracking-status {
      border-color: $intransit;
      >i {
        color: $intransit;
      }
    }
    >.text-center >.tracking-type {
      color: $intransit;
    }
  }
  >.header {
    padding: 15px 15px;
    display: flex;
    border-bottom: 1px solid #F0F0F0;
    >.text-left {
      >.tracking-status {
        margin-right: 10px;
        height: 42px;
        width: 42px;
        border: 2px solid #ddd;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        >i {
          color: #ddd;
        }
      }
    }
    >.text-center {
      flex: 1;
      >.title {
        font-size: 18px;
        color: $secondary;
      }
      >p {
        font-size: 14px;
        margin-top: -4px;
      }
    }
    >.text-right {
      color: #DADADA;
    }
  }
  >.detail {
    padding: 4px 15px 8px 15px;
    display: flex;
    >.text-left, >.text-right {
      flex: 1;
      text-align: left;
      >p {
        margin-top: -4px;
        font-size: 14px;
      }
      >small {
        font-size: 11px;
        color: $secondary;
      }
    }
    >.text-right {
      text-align: right;
    }
  }
}

.box-content {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 16px;
}

.modal-calendar {
  position: absolute;
  z-index: 2000;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  background: rgba(1, 44, 100, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    background: #FFF;
    border-radius: 5px;
  }
  .DayPicker-Day {
    height: 40px;
    width: 40px;
  }
  .modal-title {
    text-align: center;
    font-size: 18px;
    color: $secondary;
    margin-top: 14px;
  }
  .modal-footer {
    padding: 16px;
  }
}
.yas-pagination {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  overflow: hidden;
}
ul.pagination {
  display: flex;
  >li {
    border-right: none;
    background: #fff;
    flex: 1;
    border-right: 1px solid #eee;
    a {
      color: #6c7177;
      font-size: 14px;
      padding: 6px 16px;
      float: left;
      width: 100%;
      text-align: center;
    }
    &.disabled a {
      color: $gray;
    }
    &.active a {
      color: $secondary;
      background-color: #c5cdd6;
    }
    &:last-child {
      border-right: none;
    }
  }
}

.Warning {
  text-align: center;
  padding: 32px 0 0 0;
  >i {
    display: block;
    font-size: 62px;
    color: $danger;
    margin-bottom: 42px;
  }
  >.w-title {
    font-size: 18px;
    color: $secondary;
    margin-bottom: 14px;
  }
  >a {
    margin-top: 72px;
  }
}