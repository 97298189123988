@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai');

$primary: #11bef0;
$secondary: #002F6C;
$success: #28a745;
$danger: #dc3545;
$info: #17a2b8;
$warning: #ff9800;

$dark: #212223;
$gray: #CCC;
$bg: #F6F8FA;

$delivered: #1CBDA5;
$intransit: #0A4FB6;
$pack: #BD8647;
$pick: #CCB122;
$fail: #C14D48;

