* {
  font-family: 'Kanit', sans-serif;
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  background: $bg;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 300;
  color: $dark;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
ul {
  list-style: none;
}
input, select, button {
  height: 36px;
  padding: 2px 12px;
  width: 100%;
  border: 1px solid #CED4DA;
  border-radius: 5px;
  font-size: 14px;
}
select {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
  background-size: 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a, button {
  cursor: pointer;
  text-decoration: none;
  color: $secondary;
}
button, .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  background: $secondary;
  color: #fff;
  height: 42px;
  padding: 2px 12px;
  text-align: center;
  border: none;
}
[disabled] {
  background: #e6e6e6;
  color: #aaa;
}
img {
  display: block;
  max-width: 100%;
}
